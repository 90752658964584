<template>
  <div>
    <title>SIM INVENTORY ~ EDIT ORDER DETAIL LIST DATA</title>
    <section class="content-header">
      <h1>
        Edit Order Detail List Data
        <br />
        <h4>Please update the transaction data for the detailed order list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List Order Details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Detail Order List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="nomerorderd"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="idorderd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Part Component Code</label>
                    <input
                      type="text"
                      v-model="partnod"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Part Component Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getpart()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notesorderd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="qtyd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/daftar-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdatad",
  data() {
    return {
      loading: false,
      idorderd: "",
      nomerorderd: "",
      partnod: "",
      notesorderd: "",
      qtyd: "",
      namapart: ""
    };
  },
  created() {
    this.fetchdata();
  },
  methods: {
    async fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d/getdaftar_order_dbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "daftar_order_d/getdaftar_order_dbyid?id=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idorderd = resp.data.data.id;
          this.nomerorderd = resp.data.data.nomor_order;
          this.partnod = resp.data.data.part_no;
          this.qtyd = resp.data.data.qty;
          this.notesorderd = resp.data.data.notes;

          // const urlAPIgetp = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.partnod;
          const urlAPIgetp =
            this.$apiurl +
            "part_component/getpart_componentbypart_no?part_no=" +
            this.partnod;
          axios.get(urlAPIgetp, { headers }).then((respn) => {
            this.namapart = respn.data.data.name;
            this.loading = false;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async getpart() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.partnod;
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.partnod;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code / Part Number not found",
              showConfirmButton: false
            });
          } else {
            this.namapart = resp.data.data.name;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      var nomerorder = this.nomerorderd;
      var partno = this.partnod;
      var notesorderd = this.notesorderd;
      var qty = this.qtyd;
      var idx = this.idorderd;
      if (nomerorder == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (partno == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notesorderd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          nomor_order: this.nomerorderd,
          part_no: this.partnod,
          notes: this.notesorderd,
          qty: this.qtyd,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d/updatedaftar_order_d/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl + "daftar_order_d/updatedaftar_order_d/" + idx;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
